.k-button-flat-primary {
  color: var(--theme-default) !important;
}
.tab-wrapper .MuiTab-wrapper {
  display: flex;
  flex-direction: row;
}

div.k-filtercell-operator {
  display: none;
}
div.k-filtercell-wrapper {
  border: 1px solid black;
}
input.k-input-inner {
  background-color: white;
}
button.k-button.k-button-md.k-button-solid.k-button-solid-base.k-icon-button.k-input-button {
  background-color: white;
}
.hideFilter {
  display: none;
}
/* .k-grid-toolbar a {
    float: right;
  } */
.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered.css-pwcg7p-MuiCollapse-root {
  overflow: hidden;
}
.css-bz4dnt-MuiCollapse-root {
  overflow: hidden !important;
}
.k-input-inner {
  z-index: 0;
}
.css-15v22id-MuiAccordionDetails-root {
  padding: 0 !important;
}
