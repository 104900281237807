.isActiveLabel {
    font-size: 18px !important;
}
.isActiveAlignCenter {
    display: flex;
    align-items: end;
}
.trashIcon {
    margin-right: 10px;
    margin-top: 3px;
}
.empCertificationManageDiv {
    padding: 0 30px;
}
.buttonRow {
    display: flex;
    align-items: center;
    justify-content: end;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: grey !important;
}
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root.Mui-error, .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: grey !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #1976d2 !important;
}
