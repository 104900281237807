/**
Basic Table Styles
 */
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

table.simple {
  width: 100%;
  border: none;
  border-spacing: 0;
  text-align: left;
}

table.simple thead tr th {
  padding: 16px 8px;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}

table.simple thead tr th:first-child {
  padding-left: 24px;
}

table.simple thead tr th:last-child {
  padding-right: 24px;
}

table.simple tbody tr td {
  padding: 16px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.tricolor:nth-child(odd) {
  background: #000;
}
.tricolor:nth-child(even) {
  background: red;
}

table.simple tbody tr td:first-child {
  padding-left: 24px;
}

table.simple tbody tr td:last-child {
  padding-right: 24px;
}

table.simple tbody tr:last-child td {
  border-bottom: none;
}

table.simple.clickable tbody tr {
  cursor: pointer;
}

table.simple.clickable tbody tr:hover {
  background: rgba(0, 0, 0, 0.03);
}

.paroll-contents-table {
  border-collapse: separate;
  border-spacing: 0px 4px;
}

.payperiodRange select {
  padding-right: 16px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border: 1px solid white;
}

.payperiodRange select::after {
  padding-right: 16px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border: 1px solid white;
}
.payperiodRange select > div > div:focus {
  padding-right: 16px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border: 1px solid white !important;
}
.payperiodRange svg {
  color: white;
}
.earningReportRange svg {
  color: grey !important;
}
.payperiodRange fieldset {
  color: white;
  border: 1px solid white !important;
}
.payperiodRange select:focus {
  padding-right: 16px;
  font-weight: bold;
  font-size: 16px;
  color: white;
  border: 1px solid white !important;
  outline: 1 !important;
}

.tpicker {
  border: 1px solid #71717159;
  padding: 10px 10px;
  border-radius: 2px;
  margin-top: 5px;
}
.tpicker:hover {
  border: 1px solid #111827;
  padding: 10px 10px;
  border-radius: 2px;
  margin-top: 5px;
}

.react-time-picker__wrapper {
  border: none !important;
}

.MuiPickersDay-dayDisabled {
  background-color: #f8f8f8;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 2px solid #f44336;
}

.empPayTitle {
  /* background-color: rgb(94, 162, 153); */
  background-color: rgb(169, 169, 169);
}

.empPayTitleR1 {
  background-color: #fbfbfb;
}
.empPayTitleR2 {
  background-color: #e3e3e36b;
}

/* .trRow1 {
    background-color: #fbfbfb;
}
.trRow2 {
    background-color: #e3e3e36b;
} */
.tableErrorBg {
  background-color: #fff6f6;
}
/* .trRow1, .trRow2 .fadeOut{
    opacity:0;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}
.trRow1, .trRow2 .fadeIn{
    opacity:1;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
} */

/* report css */

.reportTbl .t18 {
  font-size: 18px;
}
.reportTbl .t16 {
  font-size: 16px;
}

.reportTbl .table100 {
  width: 100%;
}

.reportTbl .leftHead {
  text-align: left;
}
.reportTbl .rightHead {
  text-align: right;
}
.reportTbl .centerDiv {
  width: 100%;
  text-align: center;
}
.reportTbl .fright {
  float: right;
  text-align: justify;
}
.reportTbl .tl {
  text-align: left;
}
.reportTbl .tl {
  text-align: left;
}

.reportTbl .tl1 {
  text-align: left;
  padding-left: 15px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.reportTbl .tr1 {
  text-align: right;
  padding-right: 15px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.reportTbl .tl1Header {
  text-align: left;
  padding-left: 22px !important;
}
.reportTbl .txttop {
  vertical-align: text-top;
}

.reportTbl .tr {
  text-align: right;
}
.reportTbl .tc {
  text-align: center;
}
.reportTbl .mainHead {
  padding: 5px;
  background-color: #dcdcea;
  font-size: 16px;
  text-transform: uppercase;
}
.reportTbl .mainHead1 {
  padding: 8px !important;
  background-color: #dcdcea;
  font-size: 16px;
}

.reportTbl .padTbl th,
td {
  padding: 5px;
  font-weight: 500;
}

.reportTbl .w25 td {
  width: 25%;
}
.reportTbl .w25 th {
  width: 25%;
}

.reportTbl .gross {
  border: 1px solid gray;
  background-color: #e7e7f0;
}

.accccc {
  background-color: red !important;
  font-size: 500px !important;
  color: red !important;
}

tr.MuiTableRow-root:nth-child(even) {
  background-color: white;
}

/* end of report css */
