.subcontainer {
    margin-top: 130px;
    background-color: white;
    border-radius: 1rem;
  }

  .container{
    width: '100%';
    padding-top: '100%';
    overflow: 'hidden';
    position: 'relative';
     /* padding: 1rem; */
     height: 50vh;
     margin-bottom: 1rem;
  }

  .scanbox {
    font-family: "Montserrat", sans-serif;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #video{
    object-fit: contain !important;
  }


  .nodata {
    text-align: center;
  }