.insideDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.MuiDialogTitle-root {
    padding-bottom: 0 !important;
}
.empPayrollDetailDiv {
    width: 550px !important;
    padding: 4%;
    padding-top: 0;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root.Mui-disabled {
    background-color: grey !important;
    cursor: not-allowed !important;
}