.error {
  color: #fc8181;
  font-size: 0.75rem;
  text-align: left;
  margin: 0;
}

input.input-error,
select.input-error {
  border-color: #fc8181;
}
