.search-logo {
  width: 30px;
  height: 30px;
  margin-right: 4px;
}
.main-div {
  display: flex;
  justify-content: center;
  padding: 28px;

  min-height: 100vh;
}
.white-card {
  padding: 30px;
  background: white;
}

.main-header-text {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a2d3e;
  padding: 10px;
  margin-bottom: 10px;
}

.simple-search-div {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0px;
}
.search-mode-div {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px;
}

.form-parent-div {
  display: flex;
  justify-content: center;
  /* margin: auto;
  width: 50%; */
}

.form-space {
  display: flex;
  height: 58px;
}
.btn-max-width {
  max-width: 111px;
}
.ant-table-tbody > tr > td {
  padding: 8px 12px !important;
}
.link-btn {
  color: white;
  background: #4f9c4f;
  padding: 8px 20px;
}
.link-btn:hover {
  color: white;
}
.add-btn {
  /* width: 40%; */
  background: #4f9c4f !important;
  color: white !important;
  border-color: #4f9c4f !important;
}
.add-btn:hover {
  background: #387438 !important;
  border-color: #387438 !important;
}
.add-btn:focus {
  background: #4f9c4f !important;
  border-color: #4f9c4f !important;
}
.drawer-tag {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 2px;
  color: grey;
}
.ant-drawer-content-wrapper {
  min-width: 320px;
}
.search_img2 {
  font-size: 32px;
  margin-right: 3px;
  color: #fff;
}
.ant-btn-primary {
  background: #1a2d3e;
  border-color: #1a2d3e;
}
.ant-btn-primary:hover {
  background: #152533;
  border-color: #142431;
}
.ant-btn-primary:focus {
  background: #152533;
  border-color: #142431;
}
.ant-btn-primary:active {
  background: #152533;
  border-color: #142431;
}
.ant-form-item-explain.ant-form-item-explain-error {
  font-size: 10px;
}
.ant-form-item-control {
  height: 40px;
}
.ant-input {
  border-radius: 10px !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 10px !important;
}
.ant-btn-primary {
  border-radius: 12px !important;
}
.ant-table-filter-column {
  background: "#6b7d8e";
  color: "white";
}

.MuiAutocomplete-listbox {
  background-color: rgb(218, 218, 232) !important;
}
.img-wrap,
.dropzone-wrap {
  position: relative;
}
.img-wrap .close {
  position: absolute;
  left: 143px;
  top: -7px;
}
.dropzone-wrap .reset {
  position: absolute;
  left: 386px;
  top: 216px;
}
.ant-popover {
  z-index: 1400 !important;
}

.MuiDataGrid-row.Mui-odd {
  background-color: aliceblue;
}
.empCertificationDataTable {
  box-shadow: none !important;
}
.w4HeaderRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.w4HeaderRow h5 {
  width: auto !important;
}
