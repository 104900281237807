.closebtn:hover {
  color: rgb(212, 212, 212);
}
.leftmsg {
  background-color: white;
  margin: 10px 50px 10px 10px;
  border-radius: 5px;
  display: flex;
}
.leftmsg .txt {
  margin-left: 5px;
  border-radius: 5px;
  width: 100%;
}
.leftmsg .txtinfo {
  padding: 5px 5px 5px 10px;
  background: #f5f0f0;

  border-radius: 5px;
  width: 100%;
}
.greyBtn {
  color: #d2d2d2 !important;
}
.leftmsg .senderinfo {
  padding: 2px 5px 5px 10px;
  color: #494848;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}

/* right side info */
.rightmsg {
  background-color: white;
  margin: 10px 10px 10px 50px;
  border-radius: 5px;
  display: flex;
}
.rightmsg .txt {
  border-radius: 5px;
  width: 100%;
}
.rightmsg .txtinfo {
  padding: 5px 5px 5px 10px;
  background: #dfeff6;
  margin-left: 5px;
  border-radius: 5px;
  width: 100%;
}

.rightmsg .senderinfo {
  padding: 2px 5px 5px 10px;
  color: #494848;
  display: flex;
  justify-content: space-between;
  font-size: 10px;
}
.rightavtar {
  margin-left: 10px;
}
.leftavtar {
  margin-right: 10px;
}
.dataContainer {
  height: 280px;
  overflow-y: auto;
}
.paroll-autocomplete {
  margin-top: 5px;
}
.paroll-autocomplete .MuiAutocomplete-inputRoot {
  padding: 0;
  font-size: 14 !important;
}
.paroll-autocomplete .MuiAutocomplete-input {
  min-width: 35px !important;
  font-size: 14 !important;
}

.paroll-autocomplete .MuiOutlinedInput-root {
  min-width: 5px;
}
/* .paroll-autocomplete .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
}
.paroll-autocomplete .MuiInputLabel-outlined {
  transform: translate(12px, 11px) scale(1);
} */
.payroll-table-date-input {
  padding: 9px 10px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  width: 100%;
  margin-top: 6px;
}
.payroll-table-date-input:focus {
  padding: 7px 10px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
}
