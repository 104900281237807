@import "./assets/scss/app.scss";
// @import "../src/styles/index.css";
// @import "../src/styles/ta.css";
@import "../src/styles/print.css";

@import "../src/styles/tables.css";
@import "../src/styles/custom.css";
@import "../src/styles/muiTables.css";

@import "../src/styles/react-table.css";

@import "../src/styles/prism.css";
.page-content {
  max-height: calc(100vh - 180px);
  padding-top: 3px;
  // overflow: auto;
}
.page-content .card-body {
  padding: 0px;
}
