.customDialogue .k-window.k-dialog {
  height: 360px;
  width: 1200px;
}
.noDataClass .k-window.k-dialog {
  height: 170px;
  width: 300px;
}
div.k-window-content.k-dialog-content {
  margin-top: -28px;
}
