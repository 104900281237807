.empDeductionDetailModalDiv {
    width: 500px !important;
    padding: 3%;
}

.empDeductionDetailModalDiv h5 {
    /* margin: 0 !important; */
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
    color: #1976d2 !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: grey !important;
}
.Mui-error .MuiOutlinedInput-notchedOutline {
    border: 2px solid !important;
}