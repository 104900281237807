.upclass {
  color: green !important;
}
.downclass {
  color: red !important;
}

.abcd {
  color: red;
}

.myStickyDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
    
  z-index: 100;
}
.selectDefault {
  color: black;
  padding: 5px 10px 5px 10px;
  border: 1px solid black;
  background-color: white;
  border-radius: 20px;
}

.selectDefault:hover {
  background-color: var(--theme-default);
  color: white;
}

.notSelected {
  color: white;
  border-radius: 20px;
  border: 1px solid #26695c;
  padding: 5px 10px 5px 10px;
  background-color: var(--theme-default);
}

.notSelected:hover {
  background-color: var(--theme-default);
}
.stickyDiv {
  position: sticky;
  top: 60;
}

.chipbtn {
  border-color: var(--theme-default) !important;
  color: var(--theme-default) !important;
}

.chipbtn:hover {
  background-color: var(--theme-default) !important;
  color: white !important;
}

.chipbtnchecked {
  border-color: var(--theme-default) !important;
  color: white !important;
  background-color: var(--theme-default) !important;
}

.timesheetVerify {
  border-color: var(--theme-default) !important;
  color: black !important;
  background-color: yellow !important;
}

.timesheetNotVerify {
  border-color: var(--theme-default) !important;
  color: white !important;
  background-color: red !important;
}

.selectedUser {
  border-color: var(--theme-default) !important;
  border: 4px solid var(--theme-default) !important;
}

.selectedTags {
  border-color: var(--theme-default) !important;
  color: white !important;
  background-color: var(--theme-default) !important;
}

.timesheetNoData {
  border-color: var(--theme-default) !important;
  color: white !important;
  background-color: green !important;
}

.chipbtnchecked:hover {
  background-color: var(--theme-default) !important;
  color: white !important;
  border-color: var(--theme-default) !important;
}

.timerbg {
  margin: 10px 0px 10px 10px;
  align-items: center;
  background: green;
  color: white;
  padding: 5px;
  width: 95px;
  text-align: center;
  border-radius: 5px;
}
.timerbg span {
  width: 25px;
  display: inline-block;
}

.timerbgHead {
  margin: 10px 0px 10px 10px;
  align-items: center;
  background: white;
  color: black;
  padding: 5px;
  width: 95px;
  text-align: center;
  border-radius: 5px;
}
.timerbgHead span {
  width: 25px;
  display: inline-block;
}
