.row {
    width: 100% !important;
}
.textInput {
    width: 80% !important;
}
.textInputInputProps {
    font-size: 14px;
    padding-top: 12px;
    color: black;
}