.MuiPopover-root.MuiModal-root .css-j204z7-MuiFormControlLabel-root {
  margin-left: 0;
}
.MuiPopover-root.MuiModal-root .css-1sn4lm3-MuiTypography-root {
  padding: 9px;
}
.MuiPopover-root.MuiModal-root
  .css-j204z7-MuiFormControlLabel-root
  .MuiFormControlLabel-label {
  font-size: 13px;
  font-weight: 400;
}
.MuiPopover-root.MuiModal-root
  .css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input {
  font-size: 13px;
  font-weight: 400;
}
.MuiPopover-root.MuiModal-root
  .css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 13px;
}
.MuiPopover-root.MuiModal-root .css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  font-size: 10px;
}
.MuiPopover-root.MuiModal-root .css-e784if-MuiTypography-root {
  font-size: 10px;
}
.MuiPopover-root.MuiModal-root
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
  right: 0 !important;
  top: 7px !important;
}
.MuiPopover-root.MuiModal-root .tss-oeo5eo-MUIDataTableToolbar-filterPaper {
  max-width: 25%;
}

.MuiPopover-root.MuiModal-root .css-zcmli0-MuiGrid-root > .MuiGrid-item {
  padding-top: 15px;
}
.work-table .css-i4bv87-MuiSvgIcon-root {
  width: 20px;
  height: 20px;
}
.payroll-content-date {
  margin-top: 8px;
  margin-bottom: 4px;
}
.payroll-content-date1 {
  height: 35px;
  font-size: 12;
}
.payroll-content-date1 .MuiOutlinedInput-input {
  font-size: 14px !important;
  width: 90px;
  min-height: 30 !important;

  /* margin-top: 1px !important; */
}
.payroll-content-date1 .MuiOutlinedInput-notchedOutline {
  top: -3;
  margin-top: 3px !important;
  /* min-height: 30 !important; */
}
.payroll-content-date .MuiOutlinedInput-input {
  /* padding: 10px 14px; */
  padding: 10px 2px;
  width: 75px;
  font-size: 14 !important;
  min-height: 25 !important;
  height: inherit !important;
  margin-top: 3px !important;
}

.paroll-contents-table .paroll-contents-thead .paroll-contents-th {
  padding: 0 10px;
  text-align: left;
}
.paroll-contents-table .paroll-contents-tbody .paroll-contents-td {
  /* padding: 0 10px; */
  padding: 0 2px;
  text-align: left;
}
.paroll-contents-table .paroll-contents-thead .paroll-contents-th .tabletitle {
  min-height: 50px;
  text-align: center;
}
.paroll-contents-table
  .paroll-contents-thead
  .paroll-contents-th
  .paroll-contents-btn {
  width: 26px;
  height: 27px;
  background: #24695c !important;
  color: #fff !important;
  font-size: 20px !important;
  line-height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.paroll-contents-table .paroll-contents-tbody .paroll-contents-td {
  text-align: center;
  vertical-align: middle;
}
.paroll-contents-calcle {
  /* width: 25px;
  height: 25px;
  background: #f44336; */
  color: red;
  margin-top: 5px !important;
  font-size: 16px;
  margin: 0 auto;
  display: flex;
}

.payroll-verification-table {
  row-gap: 35px;
}
.paroll-contents-td .MuiInputAdornment-positionEnd {
  margin-left: 2px;
  margin-right: 2px;
}
.paroll-contents-td .MuiOutlinedInput-adornedEnd {
  padding-right: 5px;
  height: inherit;
  top: 3px;
}
.paroll-contents-td .MuiOutlinedInput-adornedEnd button {
  padding: 2px;
}
.paroll-contents-td .MuiOutlinedInput-input {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  /* height: 30 !important; */
}
.paroll-contents-td .MuiAutocomplete-endAdornment {
  top: auto !important;
}

.paroll-contents-td .MuiAutocomplete-input {
  /* padding-left: 10px;
  padding-right: 10px; */
  font-size: 12 !important;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}
.MuiAutocomplete-tagSizeMedium {
  font-size: small !important;
}
.MuiAutocomplete-option {
  font-size: small !important;
}
