.empDeductionDetailsModalDiv {
    width: 600px;
    padding: 0 4%;
}

.MuiDialogTitle-root {
    padding-bottom: 0;
}

.w60 {
    width: 50% !important;
}